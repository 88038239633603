import styled from "styled-components";

export const CurrentStatusDiv = styled.div`
    min-width: 800px;
    background-color: #fff;
`

export const MainStatusDiv = styled.div`
  margin: 5% 0;
`
